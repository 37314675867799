<template>
  <section id="card-images">
    <!-- Route Transaksi -->
    <b-overlay :show="loading">
      <b-row class="justify-content-center">
        <b-col sm="11" md="8" lg="6">
          <b-card class="mt-2">
            <section class="d-flex">
              <div class="image-container mr-1">
                <img
                  class="img-fluid"
                  id="image-cover"
                  :src="apiFile + paketDetail.thumbnail"
                  alt="Cover Paket"
                  style="max-width: 250px"
                />
              </div>
              <div>
                <h3>
                  <strong>{{ paketDetail.name }}</strong>
                </h3>
                <p class="text-dark" v-html="paketDetail.description"></p>
                <h2>
                  <strong class="text-primary"
                    >Rp{{ formatRupiah(paketDetail.price) }}</strong
                  >
                </h2>
              </div>
            </section>
          </b-card>
          <div class="text-primary">
            <b-card no-body v-if="paymentDetail.type_payment == 'manual'">
              <b-list-group flush>
                <b-list-group-item
                  style="background-color: #e83b04"
                  class="text-light"
                  >{{ transaction.message }}
                </b-list-group-item>
              </b-list-group>

              <b-card-body>
                <b-card
                  border-variant="primary"
                  block
                  bg-variant="light-primary"
                  v-if="!expired"
                >
                  <b-row class="">
                    <b-col align="left" class="text-dark">
                      Total Pembayaran
                    </b-col>
                    <b-col align="right" class="text-dark">
                      <feather-icon icon="ClockIcon" class="mr-1" />Bayar dalam
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col align="left" class="text-primary h3">
                      <b>Rp{{ formatRupiah(transaction.amount) }}</b></b-col
                    >
                    <b-col align="right">
                      <h4 class="text-red">
                        <strong> {{ displayTimer }}</strong>
                      </h4></b-col
                    >
                  </b-row>
                </b-card>
                <b-card
                  border-variant="primary"
                  block
                  bg-variant="light-primary"
                  v-else
                >
                  <center>
                    <h1>
                      Pembayaran sudah kadaluarsa, Silahkan membeli paket
                      Kembali!!
                    </h1>
                  </center>
                </b-card>
                <b-card-text class="text-dark" v-if="!expired">
                  <b-col>
                    <b-row>
                      <b-col sm="6">
                        <b-form-group label="Rekening" label-for="rekening">
                          <v-select
                            label="name"
                            :options="transaction.accounts"
                            v-model="selected"
                            item-value="id"
                            item-text="name"
                            name="account"
                            placeholder="Pilih"
                            return-object
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label="Bukti Bayar"
                          label-for="bukti_bayar"
                        >
                          <b-form-file
                            id="bukti_bayar"
                            v-model="file"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            no-drop
                            accept="image/*"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12" v-if="selected">
                        <center>
                          <h3>{{ selected.name }}</h3>
                          <span>
                            Account Number : <b>{{ selected.number }}</b>
                          </span>
                          <br />
                          <span>{{ selected.information }}</span>
                        </center>
                      </b-col>
                      <b-col md="12" class="mt-1">
                        <b-button
                          variant="primary"
                          :disabled="!formManualValid"
                          block
                          @click="kirim"
                          >Kirim</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-card-text>
              </b-card-body>
            </b-card>
            <b-card no-body v-else>
              <div class="" v-if="transaction.status != 'PAID'">
                <b-list-group flush>
                  <b-list-group-item
                    style="background-color: #e83b04"
                    class="text-light"
                    >Ayo, Lakukan Pembayaran Segera Juga!<br />
                    No. invoice {{ transaction.no_invoice }}</b-list-group-item
                  >
                </b-list-group>

                <b-card-body>
                  <b-card
                    border-variant="primary"
                    block
                    bg-variant="light-primary"
                    v-if="expired == false"
                  >
                    <b-row class="">
                      <b-col align="left" class="text-dark">
                        Total Pembayaran
                      </b-col>
                      <b-col align="right" class="text-dark">
                        <feather-icon icon="ClockIcon" class="mr-1" />Bayar
                        dalam
                      </b-col>
                    </b-row>
                    <b-row class="mt-2">
                      <b-col align="left" class="text-primary h3">
                        <b>Rp{{ formatRupiah(transaction.amount) }}</b></b-col
                      >
                      <b-col align="right">
                        <h4 class="text-red">
                          <strong> {{ displayTimer }}</strong>
                        </h4></b-col
                      >
                    </b-row>
                  </b-card>
                  <b-card
                    border-variant="primary"
                    block
                    bg-variant="light-primary"
                    v-else
                  >
                    <center>
                      <h1>
                        Pembayaran sudah kadaluarsa, Silahkan membeli paket
                        Kembali!!
                      </h1>
                    </center>
                  </b-card>
                  <b-card-text class="text-dark">
                    <b-col>
                      <b-row>
                        <b-col cols="8">
                          <div align="left">
                            Metode Pembayaran<br /><b>{{
                              transaction.payment_name
                            }}</b>
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div align="right">
                            <b-img
                              :src="paymentDetail.icon_url"
                              width="100px"
                            />
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="8">
                          <div align="left" v-if="transaction.pay_code != null">
                            No. Virtual Account<br />
                            <span>
                              <b>{{ transaction.pay_code }}</b>
                            </span>
                          </div>
                          <div
                            align="left"
                            v-else-if="transaction.qr_url != null"
                          >
                            <b-img
                              :src="transaction.qr_url"
                              width="100%"
                            ></b-img>
                          </div>
                          <div
                            align="left"
                            v-else-if="transaction.pay_url != null"
                          >
                            <a
                              class="btn btn-gradient-success mt-1"
                              target="_blank"
                              :href="transaction.pay_url"
                              >Lanjutkan pembayaran</a
                            >
                          </div>
                        </b-col>
                        <b-col cols="4">
                          <div
                            align="right"
                            v-if="transaction.pay_code != null"
                          >
                            <br />
                            <b-button
                              v-clipboard:copy="transaction.pay_code"
                              v-clipboard:success="onCopy"
                              v-clipboard:error="onError"
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="flat-success"
                            >
                              <feather-icon icon="CopyIcon" />Salin
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-card-text>
                </b-card-body>
              </div>
              <div v-else>
                <b-card-body>
                  <b-card
                    border-variant="primary"
                    block
                    bg-variant="light-primary"
                  >
                    <b-card-title><b>Ringkasan Pembayaran </b></b-card-title>
                    <b-card-text>
                      <b-row>
                        <b-col md="9"> Harga E-book </b-col>
                        <b-col md="3" align="right"
                          >Rp{{ formatRupiah(paketDetail.price) }}
                        </b-col>
                      </b-row>
                      <b-row v-if="transaction.discount != null" class="mt-1">
                        <b-col md="9">
                          <h5 class="text-primary">
                            Diskon {{ transaction.discount.name }}
                          </h5>
                        </b-col>
                        <b-col md="3" align="right">
                          <h5 class="text-primary">
                            -Rp{{ formatRupiah(potongan) }}
                          </h5>
                        </b-col>
                      </b-row>
                      <hr />
                    </b-card-text>
                    <b-card-text>
                      <div class="mt-2 mb-2">
                        <b-row>
                          <b-col md="9"><b>Total Harga</b></b-col>
                          <b-col md="3" align="right"
                            ><b>Rp{{ formatRupiah(total_harga) }}</b></b-col
                          >
                        </b-row>
                      </div>
                    </b-card-text>
                  </b-card>
                  <b-button variant="primary" block :to="{ name: 'tryout' }"
                    >Lihat Tryout Saya</b-button
                  >
                </b-card-body>
              </div>
            </b-card>
          </div>
          <div class="mb-2">
            <b-card
              no-body
              v-if="
                paymentDetail.type_payment == 'payment_gateway' &&
                transaction.status != 'PAID'
              "
            >
              <b-card-body>
                <b-card-title>
                  <b-col>
                    <b-row>
                      <b-col cols="8">
                        <div align="left">
                          <b>Tata Cara Pembayaran</b>
                        </div>
                      </b-col>
                      <b-col cols="4">
                        <div align="right">
                          <b-img :src="paymentDetail.icon_url" width="100px" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-card-title>
                <b-card-text>
                  <app-collapse class="mt-1">
                    <app-collapse-item
                      :title="item.title"
                      v-for="(item, i) in transaction.instructions"
                      :key="i"
                    >
                      <ul class="list-unstyled">
                        <ol>
                          <li v-for="step in item.steps">
                            <span v-html="step"></span>
                          </li>
                        </ol>
                      </ul>
                    </app-collapse-item>
                  </app-collapse>
                </b-card-text>
              </b-card-body>
            </b-card>
            <b-card>
              <b-button
                variant="outline-primary"
                block
                :to="{ name: 'billing' }"
                >Lihat Billing</b-button
              >
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
  <!-- Route Checkout -->
</template>

<script>
import {
  BRow,
  BOverlay,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BCardGroup,
  BButton,
  BTabs,
  BTab,
  BImg,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
  BListGroup,
  BListGroupItem,
  BCollapse,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BOverlay,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BTabs,
    BTab,
    BImg,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BCardBody,
    BCardGroup,
    BListGroup,
    BListGroupItem,
    ToastificationContent,
    BCollapse,
    AppCollapse,
    AppCollapseItem,
    vSelect,
  },
  data() {
    return {
      loading: false,
      dataBilling: this.$route.params.obj,
      id: null,
      activeAction: null,
      label: "Simpan",
      order_id: null,
      //Data
      artikel: [],
      //select
      optTipe: [
        { value: "page", text: "Page" },
        { value: "artikel", text: "Artikel" },
      ],
      optcategory_id: [],
      //Modal
      nomor: "741284716284716",
      titleModal: null,
      Modal: false,
      selected: "",
      options: [],
      file: null,
      displayTimer: null,
      expired: false,
      timer: null,
      total_harga: null,
      potongan: null,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  computed: {
    paymentDetail() {
      return this.$store.state.payment.detail;
    },
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
    transaction() {
      const dataTransaction = this.$store.state.payment.currentTransaction;

      return dataTransaction?.data ? dataTransaction.data : dataTransaction;
    },
    formManualValid() {
      if (!this.selected || !this.file) {
        return false;
      }

      return true;
    },
  },
  methods: {
    setTimer() {
      let today = new Date();
      const expired_at = new Date(this.transaction.expired_at);

      const isExpired = today >= expired_at;
      if (isExpired) {
        this.expired = true;
        return;
      }
      this.timer = setInterval(() => {
        // calculate timer between 2 date
        this.calculateDate(expired_at);
      }, 1000);
    },
    calculateDate(expiredDate) {
      let displayTimer = [];
      let today = new Date();
      const differenceTime = expiredDate.getTime() - today.getTime();

      const timeIsOver = differenceTime <= 0;

      if (timeIsOver) {
        clearInterval(this.timer);
        this.expired = true;
      } else {
        let second = Math.floor(differenceTime / 1000);
        let minute = Math.floor(second / 60);
        let hour = Math.floor(minute / 60);
        let day = Math.floor(hour / 24);

        hour %= 24;
        minute %= 60;
        second %= 60;

        if (day && day > 0) {
          displayTimer.push(`${day} hari`);
        }

        if (hour && hour > 0) {
          displayTimer.push(`${hour} jam`);
        }
        if (minute && minute > 0) {
          displayTimer.push(`${minute} menit`);
        }

        displayTimer.push(`${second} detik`);
        if (displayTimer.length > 0) {
          this.displayTimer = displayTimer.join(" ");
        }
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Berhasil menyalin teks",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Gagal menyalin teks!",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Artikel";
      this.Modal = true;
      this.getDataKategoriArtikel();
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Artikel";
      this.Modal = true;
      this.getDataKategoriArtikel();
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          transaction.fungsi = 1; //soft delete
          this.$store
            .dispatch("artikel/createUpdate", data)
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.$swal({
                icon: "success",
                title: "Terhapus!",
                text: "Data berhasil dihapus.",
                // timer: 1000,
                allowOutsideClick: true,
                customClass: {
                  confirmButton: "btn btn-success",
                },
                buttonsStyling: false,
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    kirim() {
      if (!this.formManualValid) {
        this.displayError({
          message: "Harap pilih rekening dan upload bukti bayar!",
        });
        return false;
      }
      // const payload = this.form;
      const fd = new FormData();
      fd.append("order_id", this.transaction.order_id);
      fd.append("rekening_id", this.selected.id);
      fd.append("file", this.file);

      this.loading = true;
      this.$store
        .dispatch("payment/buktiBayar", fd)
        .then(() => {
          this.loading = false;
          this.order_id = null;
          this.selected = null;
          this.file = null;
          this.displaySuccess({
            text: "Konfirmasi Paket Berhasil, Tunggu Konfirmasi dari admin",
          });
          this.$router.push({ name: "billing" });
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    getData() {
      const params = {
        type: "page",
      };
      this.$store
        .dispatch("artikel/index", params)
        .then((response) => {
          let artikel = response.transaction.data;
          this.artikel = artikel;
          this.totalRows = this.artikel.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
    async getDatarekening() {
      let rekening = await this.$store.dispatch("rekening/index", {});
      rekening = await rekening.data?.data;
      this.options = rekening.map((item) => ({
        id: item.id,
        name: item.name,
        number: item.number,
      }));
      const transaction = Object.assign({}, this.transaction);
      transaction.accounts = this.options;
      this.$store.commit("payment/SET_CURRENT_TRANSACTION", transaction);
    },
  },
  async mounted() {
    if (!this.transaction) {
      this.$router.go(-1);
    }
    await this.setTimer();
    const manualTransfer = this.transaction?.payment_type === "manual";
    if (manualTransfer) {
      this.getDatarekening();
    }
    if (this.transaction.discount.type == "nominal") {
      this.potongan = this.transaction.discount.value;
      this.total_harga =
        this.paketDetail.price - this.transaction.discount.value;
    } else {
      let hasilPersen =
        this.paketDetail.price * (this.transaction.discount.value / 100);
      this.potongan = hasilPersen;
      this.total_harga = this.paketDetail.price - hasilPersen;
    }
  },
};
</script>
<style>
@media (min-width: 425px) {
  #image-cover {
    width: 100px;
  }
}

@media (min-width: 768px) {
  #image-cover {
    width: 150px;
  }
}
</style>
