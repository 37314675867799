<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <nav
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <profile-header :header-data="profileData.header" />
    </nav>
    <section id="profile-info">
      <b-row>
        <!-- post -->
        <b-col cols="12">
          <konfirmasi-pembayaran />
        </b-col>
        <!-- post -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ProfileHeader from "./components/NavbarKonfirmasi.vue";
import KonfirmasiPembayaran from "./utama/Konfirmasi-Pembayaran.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    KonfirmasiPembayaran,
    ProfileHeader,
  },
  data() {
    return {
      profileData: {},
    };
  },
  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
